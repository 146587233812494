
  import { Component } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { GForm } from '@/components/forms/GForm'

@Component({
  computed: {
    ...mapGetters('app', ['isMini']),
  },
})
  export default class DrawerHeader extends GForm {
  isMini!: boolean;
  path = require('@/assets/Logotipo_Interno.svg');
  pathLogo = require('@/assets/logo.svg');

  goReport () {
    const path = this.$router.currentRoute.path
    const id = this.idEmployee
    const user = this.user.id

    const identifier = id ? `identifier-${id}` : `user-${user}`

    window.open(`https://forms.clickup.com/3078855/p/f/2xyp7-16217/UJFBFVMPLHZID2T20G/reportedefallas?Tipo%20de%20falla=${path}%20${identifier}-${this.user.email}`, '_blank')
  }
  }
